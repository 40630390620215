// Now we can use $ in evry script if request after this line above
window.$ = window.jQuery;


import Router from "./utils/Router";
import "./scripts/Menu";
import "./scripts/popup/newsletter";
// // Swiper
import Swiper from "./lazy/Swiper/Swiper";

// //Globals scripts
import globals from "./globals";

$(document).ready(function () {
  new Router([
    {
      attribute: ".swiper-container",
      instance: Swiper,
    },
    // {
    //   attribute: ".page-storia",
    //   instance: Storia,
    // },
    // {
    //   attribute: ".testo-home",
    //   instance: Testo,
    // },
    // {
    // },
  ]).watch();
});
