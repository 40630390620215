class Router {
  constructor(routes) {
    Object.assign(this, { routes });
  }

  /**
   * @param {string} attribute Html Classname to check
   * @param {class|string} instance Class instance or path to class
   * @param {function} [fn] Function to pass for tweak the module
   */
  watch() {
    this.routes.forEach(({ attribute, instance, fn }) => {
      if (!document.querySelector(attribute)) return;

      typeof instance === "string" ? this.lazy(instance, fn) : new instance();
    });
  }

  /**
   * Lazy load module
   * @param {string} path Path where to find the module
   * @param {function} [fn] Custom function to trigger passing module instance
   * @return {void}
   */
  lazy(path, fn) {
    /**
     * Default, class imported dynamically
     * @param {class} default
     */
    const def = ({ default: _ }) => new _();

    fn = fn ? fn : def;

    import(
      /* webpackChunkName: "[request]" */
      `../lazy/${path}`
    ).then(fn);
  }
}

export default Router;
