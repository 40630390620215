export const swiperSlide = {
  elements: Array.from(
    document.querySelectorAll(".block__slide .swiper-container")
  ),
  details: {
    autoplay: {
      delay: 5000,
    },
    // Optional parameters
    loop: true,
    slidesPerView: 1,
    spaceBetween: 0,
    speed: 600,
    threshold: 10,
    centerInsufficientSlides: true,

    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,

    // Navigation arrows
    navigation: {
      nextEl: ".block__slide .swiper-button-next",
      prevEl: ".block__slide .swiper-button-prev",
    },
    breakpoints: {
      1023: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
  },
};

// if (jQuery(".block__slide .swiper-slide").length == 3) {
//   jQuery(this)
//     .closest(".swiper-wrapper")
//     .addClass("disabled");
//   jQuery(this)
//     .closest(".slide-nav")
//     .addClass("disabled");
// }
