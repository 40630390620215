import "./Footer";
import "./Header";



jQuery(document).ready(function ($) {
  $(".backplate").click(function () {
    $(".menu-tendina").removeClass("menu-tendina--active");
    $(".o-popup").removeClass("o-popup--open");
    $(this).removeClass("backplate--active");
  });
});