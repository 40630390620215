export const swiperPartners = {
  elements: Array.from(
    document.querySelectorAll(".partner-links .swiper-container")
  ),
  details: {
    // Optional parameters
    loop: true,
    slidesPerView: 4,
    spaceBetween: 60,
    speed: 600,
    threshold: 10,

    // Disable preloading of all images
    // preloadImages: false,
    // Enable lazy loading
    lazy: true,

    // If we need pagination
    // pagination: {
    //   el: ".swiper-pagination",
    // },
    // Navigation arrows
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    breakpoints: {
      1023: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
    },
  },
};
