(function ($) {
  window.isAnimating = false;
  const animationDuration = 200;

  const menuItemsSub = document.querySelectorAll(".menu-item-sub");

  function menuMobile() {
    $(".hamburger").click(function () {
      $(".menu-tendina").addClass("menu-tendina--active");
      $(".backplate").addClass("backplate--active");
    });
    $(".close-menu").click(function () {
      $(".menu-tendina").removeClass("menu-tendina--active");
      $(".backplate").removeClass("backplate--active");
    });

    $(".menu-item-has-children > a").click(function (e) {
      e.preventDefault();
      $(this)
        .next()
        .slideToggle();
    });
  }

  $(document).on('click', 'a[href^="#"]', function (event) {
    event.preventDefault();

    $(".menu-tendina").removeClass("menu-tendina--active");
    $(".backplate").removeClass("backplate--active");

    $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top - 120
    }, 500);
  });

  $(document).ready(function () {
    menuMobile();
  });

})(jQuery);


// document.querySelectorAll('a[href^="#"]').forEach(anchor => {
//   anchor.addEventListener('click', function (e) {
//     e.preventDefault();

//     document.querySelector(this.getAttribute('href')).scrollIntoView({
//       behavior: 'smooth'
//     });
//   });
// });
