import Swiper from "swiper";

// import { swiperBottom } from "./config/swiper-bottom";
// import { swiperBirre } from "./config/swiper-birre";
// import { swiperEventi } from "./config/swiper-eventi";
import { swiperSlide } from "./config/swiper-slide";
import { swiperPartners } from "./config/swiper-partners";

const configs = [swiperPartners, swiperSlide];

/**
 * Init function for building all slides base on
 * className and configuration details
 */
export default class {
  constructor() {
    configs.forEach(({ elements, details }) => {
      elements.forEach((el) => new Swiper(el, details));
    });
  }
}