

// import Cookies from 'js-cookie';

jQuery(document).ready(function ($) {
  // var cookieName = "o-newsletter";
  // Check if we have cookie, if so return
  // if (Cookies.get(cookieName) === "1") return;
  $(".o-popup-close--newsletter").on("click", function () {
    // Handle close events
    $(".o-popup--newsletter").removeClass("o-popup--open");
    $(".backplate").removeClass("backplate--active");

    // Set cookie to 30 days if only closed the cookie
    // Cookies.set(cookieName, "1", { expires: 30 });
  });

  // Open popup after dom ready
  // setTimeout(function() {
  //   $(".o-popup--newsletter").addClass("o-popup--open");
  // }, 3000);

  $(".open-newsletter-popup").on("click", function () {
    $(".backplate").addClass("backplate--active");
    $(".o-popup--newsletter").addClass("o-popup--open");
  });
});
